export class CodigoModel{
    
    id: string;
    laboratorio_id: string;
    user_id:string;
    nombre:string;
    porcentaje:string;
    cantidad:string;
    generated:string;

    constructor(){
    }
}