import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { map } from 'rxjs/operators';
import { User } from '../modelos/user.model';



 
@Injectable({ providedIn: 'root' })

export class LoginService {

  private userSubject: BehaviorSubject<User>;
  public user: Observable<User>;


  service  = environment.urlService;

  constructor( private router: Router, private http: HttpClient ) { 

        this.userSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        this.user = this.userSubject.asObservable();

  }


  public get currentUserValue(): User {
    return JSON.parse(localStorage.getItem('currentUser'));
  }

  public get userValue(): User {
    return this.userSubject.value;
  }


  login(mail, password) {

    return this.http.post(this.service+'/codigos/laboratorios/login', { mail: mail, password: password }).pipe(map( (resp: any) => {

        if(resp.status == "ok"){
          
            let user = { mail: resp.data.email,  password: resp.data.password, rol: resp.data.rol, id: resp.data.id };
            localStorage.setItem('currentUser', JSON.stringify(user));
            this.userSubject.next(user);
    
        }

        return resp;

    }));

  }

  logout() {
    // remove user from local storage and set current user to null
    localStorage.removeItem('currentUser');
    this.userSubject.next(new User);
    this.router.navigate(['/login']);

}
  

  

}
