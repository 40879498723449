import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { LaboratorioModel } from '../modelos/laboratorio.model';
import { environment } from './../../environments/environment';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LaboratoriosService {

  service  = environment.urlService;

  constructor( private http: HttpClient ) { }

  crear( laboratorio: LaboratorioModel ){

    return this.http.post(this.service+'/codigos/laboratorios/crear', laboratorio).pipe(map( resp => {
        return resp;
    }));; 

  }

  editar( laboratorio: LaboratorioModel ){

    return this.http.post(this.service+'/codigos/laboratorios/editar', laboratorio).pipe(map( resp => {
        return resp; 
    }));; 

  }

  all() {
    return this.http.get(this.service+'/codigos/laboratorios/todos').pipe(map( resp => {
      return resp;
    }));
  }

  getLaboratorio(swal: any, id: string) : any{

    return new Promise( resolve => {
    let peticion: Observable<any>;
    peticion = this.all();

      swal.fire({
        title: '',
        icon: 'info',
        text: 'Cargando la información',
        allowOutsideClick: false,
      });
  
      swal.showLoading();

      peticion.subscribe( resp => {
        if(resp.status == "ok"){
          let laboratorios = resp.data;
          swal.close();
          laboratorios.map((res: any) => { if(res.id == id){ resolve(res); } });
        }
      });
    });
  }

}
