import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { CustomMaterialModule } from './core/material.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LaboratoriosComponent } from './paginas/laboratorios/laboratorios.component';
import { LaboratorioComponent } from './paginas/laboratorio/laboratorio.component';
import { HomeComponent } from './paginas/home/home.component';
import { CodigosComponent } from './paginas/codigos/codigos.component';
import { CodigoComponent } from './paginas/codigo/codigo.component';
import { ListadoComponent } from './paginas/codigos/listado/listado.component';
import { LoginComponent } from './paginas/login/login.component';
import { LoginService } from './services/login.service';

@NgModule({
  declarations: [
    AppComponent,
    LaboratoriosComponent,
    LaboratorioComponent,
    HomeComponent,
    CodigosComponent,
    CodigoComponent,
    ListadoComponent,
    LoginComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CustomMaterialModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
