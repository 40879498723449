export class ItemModel{
    
    id: string;
    codigo: string;
    nombre:string;
    mail:string;
    cedula:string;
    celular:string;
    admin_id:string;
    created:string;
    updated:string;

    constructor(){
    }
}