import { Component, OnInit } from '@angular/core';
import {NgForm} from '@angular/forms';
import { LaboratorioModel } from 'src/app/modelos/laboratorio.model';
import { LaboratoriosService } from 'src/app/services/laboratorios.service';
import  Swal  from 'sweetalert2';
import { ActivatedRoute } from "@angular/router";
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-laboratorio',
  templateUrl: './laboratorio.component.html',
  styleUrls: ['./laboratorio.component.scss']
})
export class LaboratorioComponent implements OnInit {
  
  laboratorios = [];
  laboratorio = new LaboratorioModel;
  id = "";

  constructor( private service : LaboratoriosService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    
    this.id = this.route.snapshot.paramMap.get("id");

    if(this.id != "crear"){

      this.service.getLaboratorio(Swal, this.id).then((data:any)=>{

        this.laboratorio = data;

     });

    }

  }

  guardar( form: NgForm){

    if(form.invalid)
    {
      return;
    }


    Swal.fire({
      title: 'Espere',
      icon: 'info',
      text: 'Guardando Información',
      allowOutsideClick: false,
    });

    Swal.showLoading();

    let peticion: Observable<any>;
    
    if(this.id == "crear"){
      peticion = this.service.crear(this.laboratorio);
    }else{
      peticion = this.service.editar(this.laboratorio);
    }

    peticion.subscribe( resp => {

      if(resp.status == "ok"){
        
        if(resp.edit != "ok"){
          this.laboratorio = new LaboratorioModel;
        }

        Swal.fire({
          title: this.laboratorio.nombre,
          icon: 'success',
          text:  resp.message
        });

      }else{

        Swal.fire({
          title: this.laboratorio.nombre,
          icon: 'error',
          text: resp.message
        });

      }
      
    });


  }

}
