<h1>Laboratorio - Grupo de codigos</h1>
<h2>{{ laboratorio.nombre }}</h2>
<p>
  <b>Cantidad Total:</b> {{ laboratorio.cantidad }}
</p>
<p>
  <b>Cantidad Restante:</b> {{ cantidadRestante }}
</p>
<hr>
<div class="row">
    <div  class="col-6">
        <button *ngIf="isAdmin()" [routerLink]="['/laboratorios']" routerLinkActive="router-link-active"  class="btn btn-danger">
            <i class="fa fa-arrow-left"></i>
            Regresar
        </button>
    </div>

    <div class="col-6 text-right">
      <button  (click)="descargar()" style="margin-right: 5px;" class="btn btn-primary">
        <i class="fa fa-download"></i>
         Codigos
      </button>
      <button style="margin-right: 5px;"  (click)="descargar_usuarios()" class="btn btn-primary ml-1">
        <i class="fa fa-download"></i>
         Usuarios
      </button>
      <button  [routerLink]="['/laboratorios/codigos/crear/', id]" routerLinkActive="router-link-active" routerLinkActive="router-link-active"  class="btn btn-primary">
        <i class="fa fa-plus"></i>
        Agregar
      </button>
    </div>

</div>
<br>
<table class="table">
    <thead class="thead-dark">
      <tr>
        <th scope="col">#</th>
        <th scope="col">Nombre</th>
        <th scope="col">Porcentaje</th>
        <th scope="col">Cantidad</th>
        <th scope="col">Creado</th>
        <th scope="col">Acciones</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of codigos">
        <th scope="row">{{ item.id }}</th>
        <td>{{ item.nombre }}</td>
        <td>{{ item.porcentaje }}</td>
        <td>{{ item.cantidad  }}</td>
        <td>{{ item.created }}</td>
        <td>
          <button [routerLink]="['/laboratorios/codigos/listado/', id, item.id]" routerLinkActive="router-link-active" class="btn btn-primary w-35 ml-1"> 
            <i class="fa fa-plus"></i></button>
        </td>
      </tr>
    </tbody>
  </table>