import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {NgForm} from '@angular/forms';
import { LaboratorioModel } from 'src/app/modelos/laboratorio.model';
import { CodigoModel } from 'src/app/modelos/codigo.model';
import { LaboratoriosService } from 'src/app/services/laboratorios.service';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2';
import { CodigosService } from 'src/app/services/codigos.service';

@Component({
  selector: 'app-codigo',
  templateUrl: './codigo.component.html',
  styleUrls: ['./codigo.component.scss']
})
export class CodigoComponent implements OnInit {

  id = '';
  laboratorio = new LaboratorioModel;
  codigo = new CodigoModel;
  cantidadRestante = 0;
  cantidadSum = 0;
  codigos = [];

  constructor(private serviceLabo: LaboratoriosService, private service: CodigosService, private route: ActivatedRoute) { }

  ngOnInit(): void {

    this.id = this.route.snapshot.paramMap.get("id");
    
    this.serviceLabo.getLaboratorio(Swal, this.id).then((data:any)=>{

      this.laboratorio = data;

      this.cantidadRestante = parseInt(this.laboratorio.cantidad) - this.cantidadSum;

   });

   this.codigo.user_id = "0";
   this.codigo.laboratorio_id = this.id;
   this.codigo.porcentaje = '100';


    let peticion: Observable<any>;
    peticion = this.service.all(this.id);
    peticion.subscribe( resp => {

      if(resp.status == "ok"){

        this.codigos = resp.data;
        this.codigos.map((res: any) => {  this.cantidadSum = this.cantidadSum + parseInt(res.cantidad)});

        this.cantidadRestante = parseInt(this.laboratorio.cantidad) - this.cantidadSum;
      }

    });

  }



  guardar( form: NgForm){

    if(form.invalid)
    {
      return;
    }


    Swal.fire({
      title: 'Espere',
      icon: 'info',
      text: 'Guardando Información',
      allowOutsideClick: false,
    });

    Swal.showLoading();

    let peticion: Observable<any>;
    
    peticion = this.service.crear(this.codigo);

    peticion.subscribe( resp => {

      if(resp.status == "ok"){

        Swal.fire({
          title: this.laboratorio.nombre,
          icon: 'success',
          text:  resp.message
        });

        this.codigo = new CodigoModel;

      }else{

        Swal.fire({
          title: this.laboratorio.nombre,
          icon: 'error',
          text: resp.message
        });

      }
      
    });

  }

  

}
