import { Component, OnInit } from '@angular/core';
import  Swal  from 'sweetalert2';
import { ActivatedRoute } from "@angular/router";
import { Observable } from 'rxjs';
import { CodigosService } from 'src/app/services/codigos.service';
import { LaboratorioModel } from 'src/app/modelos/laboratorio.model';
import { LaboratoriosService } from 'src/app/services/laboratorios.service';
import { CodigoModel } from 'src/app/modelos/codigo.model';
import { ItemModel } from 'src/app/modelos/items.model';


@Component({
  selector: 'app-listado',
  templateUrl: './listado.component.html',
  styleUrls: ['./listado.component.scss']
})
export class ListadoComponent implements OnInit {

  codigos = [];
  codigo_id = '';
  cantidadSum = 0;
  id = "";
  codigo = new CodigoModel;
  cantidadRestante = 0;
  laboratorio = new LaboratorioModel;
  items:ItemModel[] = [];
  item = new ItemModel;

  constructor(private service : CodigosService, private route: ActivatedRoute, private serviceLabo : LaboratoriosService ) { }

  ngOnInit(): void {

    this.id = this.route.snapshot.paramMap.get("id");
    this.codigo_id = this.route.snapshot.paramMap.get("codigo");

    Swal.fire({
      title: '',
      icon: 'info',
      text: 'Cargando la información',
      allowOutsideClick: false,
    });

    Swal.showLoading();

    let peticion: Observable<any>;
    peticion = this.service.all(this.id);
    peticion.subscribe( resp => {

      if(resp.status == "ok"){

        this.codigos = resp.data;
        Swal.close();
        this.codigos.map((res: any) => { 
           this.cantidadSum = this.cantidadSum + parseInt(res.cantidad);
           if(this.codigo_id == res.id){
             this.codigo = res;
           }
        });

        this.cantidadRestante = parseInt(this.laboratorio.cantidad) - this.cantidadSum;
      }

    });

     this.serviceLabo.getLaboratorio(Swal, this.id).then((data:any)=>{

        this.laboratorio = data;
        this.cantidadRestante = parseInt(this.laboratorio.cantidad) - this.cantidadSum;

     });


     this.listado();

  }


  listado(){

    let peticion: Observable<any>;
    peticion = this.service.obtenerItem(this.codigo_id);
    peticion.subscribe( resp => {

      if(resp.status == "ok"){

        this.items = resp.data;

      }

    });

  }

  generar(){
    

    Swal.fire({
      title: 'Seguro de generar los Codigos?',
      showDenyButton: true,
      confirmButtonText: `Generar`,
      denyButtonText: `Cancelar`,
    }).then((result) => {

      this.codigo.generated = '1';
      
      if (result.isConfirmed) {

        let peticion: Observable<any>;
        peticion = this.service.generar(this.codigo_id);
        peticion.subscribe( resp => {


          if(resp.status == "ok"){  
            
            this.codigo.generated = '1';
            Swal.fire('Generados!', '', 'success');

            this.listado();
    
          }else{

            this.codigo.generated = '0';

          }

        });
      } 

    });

  }

  descargar(){
    this.service.downloadFile(this.items, 'listado', ['codigo']);
  }

  descargar_usuarios(){
    this.service.downloadFile(this.items, 'listadousuarios', ['codigo', 'field_nombres_value', 'mail', 'field_cedula_value', 'field_celular_value', 'created', 'updated']);
  }

  delete(item_id){

    Swal.fire({
      title: 'Seguro de eliminar?',
      showDenyButton: true,
      confirmButtonText: `Eliminar`,
      denyButtonText: `Cancelar`,
    }).then((result) => {
      

      if (result.isConfirmed) {

        let peticion: Observable<any>;
        peticion = this.service.eliminar(item_id);
        peticion.subscribe( resp => {


          if(resp.status == "ok"){  
            
            this.codigo.generated = '1';
            Swal.fire('Eliminado!', resp.message, 'success');
            this.listado();
    
          }else{

            this.codigo.generated = '0';

          }

        });

      }



    });

  }

}
