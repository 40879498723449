import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { environment } from './../../environments/environment';
import { map } from 'rxjs/operators';
import { CodigoModel } from '../modelos/codigo.model';

@Injectable({
  providedIn: 'root'
})
export class CodigosService {

  service  = environment.urlService;

  constructor( private http: HttpClient ) { }

  all(id: string) {
    return this.http.get(this.service+'/codigos/laboratorios/codigos/todos?id='+id).pipe(map( resp => {
      return resp;
    }));
  }

  crear( codigo: CodigoModel ){

    return this.http.post(this.service+'/codigos/laboratorios/codigos/crear', codigo).pipe(map( resp => {
        return resp;
    }));; 

  }

  generar( id : string ) {
    return this.http.post(this.service+'/codigos/laboratorios/codigos/generar', { "id" : id }).pipe(map( resp => {
      return resp;
    }));
  }

  obtenerItem( id : string ){

    return this.http.get(this.service+'/codigos/laboratorios/codigos/listados/todos?id='+id).pipe(map( resp => {
      return resp;
    }));

  }

  obtenerItems( id : string ){

    return this.http.get(this.service+'/codigos/laboratorios/codigos/todoslista?id='+id).pipe(map( resp => {
      return resp;
    }));

  }


  eliminar( id : string ){
    return this.http.post(this.service+'/codigos/laboratorios/codigos/listados/eliminar', { "id" : id }).pipe(map( resp => {
      return resp;
    }));
  }
 
  downloadFile(data, filename='data', vr:Array<string> ) {
    let csvData = this.ConvertToCSV(data, vr);
    console.log(csvData)
    let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
    if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
        dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", filename + ".csv");
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
    }

    ConvertToCSV(objArray, headerList) {
        let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
        let str = '';
        let row = '#,';

        for (let index in headerList) {
            row += headerList[index] + ',';
        }
        row = row.slice(0, -1);
        str += row + '\r\n';
        for (let i = 0; i < array.length; i++) {
            let line = (i+1)+'';
            for (let index in headerList) {
                let head = headerList[index];

                line += ',' + array[i][head];
            }
            str += line + '\r\n';
        }
        return str;
      }

  



} 
