<h1 style="margin-bottom: 6px;">Laboratorio - Grupo de codigos</h1>
<h2 style="margin-bottom: 8px;">{{ laboratorio.nombre }}</h2>
<div class="row">
  <div class="col-12 col-md-6">
    <p>
      <b>Grupo de codigos:</b> {{ codigo.nombre }}
    </p>
    <p>
        <b>Porcentaje:</b> {{ codigo.porcentaje }}
    </p>
  </div>
  <div class="col-12 col-md-6">
    <p>
      <b>Cantidad Total:</b> {{ laboratorio.cantidad }}
    </p>
    <p>
      <b>Cantidad Restante:</b> {{ cantidadRestante }}
    </p>
  </div>
</div>
<hr>
<br>
<div class="row">

    <div class="col-6">
        <button [routerLink]="['/laboratorios/codigos/', id]" routerLinkActive="router-link-active"  class="btn btn-danger">
            <i class="fa fa-arrow-left"></i>
            Regresar
        </button>
    </div>

    <div class="col-6 text-right">
      <button *ngIf="codigo.generated == '0'"  (click)="generar()" class="btn btn-primary">
        <i class="fa fa-plus"></i>
        Generar Codigos
      </button>

      <button *ngIf="codigo.generated == '1'"  (click)="descargar()" class="btn btn-primary">
        <i class="fa fa-download"></i>
         Codigos
      </button>

      <button *ngIf="codigo.generated == '1'"  (click)="descargar_usuarios()" class="btn btn-primary ml-1">
        <i class="fa fa-download"></i>
         Usuarios
      </button>
      
    </div>

</div>
<br>
<table class="table">
    <thead class="thead-dark">
      <tr>
        <th scope="col">#</th>
        <th scope="col">codigo</th>
        <th scope="col">nombre</th>
        <th scope="col">mail</th>
        <th scope="col">cedula</th>
        <th scope="col">celular</th>
        <th scope="col">fecha</th>
        <th scope="col">actualizado</th>
        <th scope="col">acciones</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of items ">
        <th scope="row">{{ item.id }} </th>
        <td>{{ item.codigo }}</td>
        <td>{{ item.field_nombres_value }}</td>
        <td>{{ item.mail }}</td>
        <td>{{ item.field_cedula_value }}</td>
        <td>{{ item.field_celular_value }}</td>
        <td>{{ item.created }}</td>
        <td>{{ item.updated }}</td>
        <td>
          <button *ngIf="codigo.porcentaje == '100'" (click)="delete(item.id)" class="btn btn-danger w-35 ml-1"> 
            <i class="fa fa-trash"></i></button>
        </td>
      </tr>
    </tbody>
  </table>