import { Component, OnInit } from '@angular/core';
import  Swal  from 'sweetalert2';
import { ActivatedRoute } from "@angular/router";
import { Observable } from 'rxjs';
import { CodigosService } from 'src/app/services/codigos.service';
import { LaboratorioModel } from 'src/app/modelos/laboratorio.model';
import { LaboratoriosService } from 'src/app/services/laboratorios.service';
import { LoginService } from 'src/app/services/login.service';
import { User } from 'src/app/modelos/user.model';

@Component({
  selector: 'app-codigos',
  templateUrl: './codigos.component.html',
  styleUrls: ['./codigos.component.scss']
})
export class CodigosComponent implements OnInit {

  codigos = [];
  cantidadSum = 0;
  id = "";
  cantidadRestante = 0;
  laboratorio = new LaboratorioModel;
  user: User;
  items = [];

  constructor(private service : CodigosService, private loginService:LoginService, private route: ActivatedRoute, private serviceLabo : LaboratoriosService ) { 
      
      this.loginService.user.subscribe( (user:User) => 
      {
        this.user = user
      });

  }

  isAdmin(){
    if(this.user.rol == "admin"){
      return true;
    }
    return false;
  }

  ngOnInit(): void {

    this.id = this.route.snapshot.paramMap.get("id");
    
    this.listado();

    Swal.fire({
      title: '',
      icon: 'info',
      text: 'Cargando la información',
      allowOutsideClick: false,
    });

    Swal.showLoading();

  

    let peticion: Observable<any>;
    peticion = this.service.all(this.id);
    peticion.subscribe( resp => {

      if(resp.status == "ok"){

        this.codigos = resp.data;
        Swal.close();
        this.codigos.map((res: any) => {  this.cantidadSum = this.cantidadSum + parseInt(res.cantidad)});

        this.cantidadRestante = parseInt(this.laboratorio.cantidad) - this.cantidadSum;
      }

      });

     this.serviceLabo.getLaboratorio(Swal, this.id).then((data:any)=>{

        this.laboratorio = data;
        this.cantidadRestante = parseInt(this.laboratorio.cantidad) - this.cantidadSum;

     });


  }

  
  listado(){

    let peticion: Observable<any>;
    peticion = this.service.obtenerItems(this.id);
    peticion.subscribe( resp => {

      if(resp.status == "ok"){

        this.items = resp.data;

      }

    });

  }


  descargar(){
    this.service.downloadFile(this.items, 'listado', ['codigo', 'nombre', 'cl_nombre']);
  }

  descargar_usuarios(){
    this.service.downloadFile(this.items, 'listadousuarios', ['codigo' , 'nombre', 'cl_nombre', 'field_nombres_value', 'mail', 'field_cedula_value', 'field_celular_value', 'created', 'updated']);
  }


  

}
