import { Component, OnInit  } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from './services/login.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'econgresscodigo';
  user:any;
  constructor(private service: LoginService,
    private router: Router){
        this.service.user.subscribe( user => 
        {
          this.user = user
        });
    
  }

  ngOnInit() {


    if (this.service.userValue) {
      this.router.navigate(['/']);
     }

  }

  logout() {
    this.service.logout();
    this.user = this.service.userValue;
  }

}
