<h1>Laboratorio</h1>
<hr>
<div class="row">

    <div class="col">
        <button [routerLink]="['/laboratorios']" routerLinkActive="router-link-active"  class="btn btn-danger">
            <i class="fa fa-arrow-left"></i>
            Regresar
        </button>
    </div>


</div>

<br>

<div class="row">
    <div class="col">

        <form (ngSubmit)="guardar( f )" #f="ngForm">

            <div class="form-group">
                <label>ID</label>
                <input type="text"
                        class="form-control"
                        disabled="disabled"
                        [(ngModel)]="laboratorio.id" 
                        name="id" />
                <small class="form-text text-muted">Este campo es autogenerado.</small> 
            </div>

            <div class="form-group">
                <label>Nombre</label>
                <input type="text"
                        class="form-control"
                        [(ngModel)]="laboratorio.nombre" 
                        name="nombre"
                        placeholder="Nombre del Laborario" required />
            </div>

            <div class="form-group">
                <label>E-mail</label>
                <input type="email"
                        class="form-control"
                        [(ngModel)]="laboratorio.email" 
                        name="email"
                        placeholder="Email del Laborario" required />
            </div>

            <div class="form-group">
                <label>Contraseña</label>
                <input type="text"
                        class="form-control"
                        [(ngModel)]="laboratorio.password" 
                        name="password"
                        placeholder="" required />
            </div>

            <div *ngIf=" id != 'crear' ;else cantidadCodigo" class="form-group">
                <label>Cantidad de códigos</label>
                <input type="number" disabled 
                        class="form-control"  
                        [(ngModel)]="laboratorio.cantidad" 
                        name="cantidad"
                        placeholder="Cantidad de códigos del Laborario" required />
            </div>
            <ng-template #cantidadCodigo>
                <label>Cantidad de códigos</label>
                <input type="number"
                        class="form-control"  
                        [(ngModel)]="laboratorio.cantidad" 
                        name="cantidad"
                        placeholder="Cantidad de códigos del Laborario" required />
            </ng-template>

            

            <div class="form-group">
                <label>Estado</label>
                <br>
                <button *ngIf="laboratorio.status" (click)="laboratorio.status = false" class="btn btn-outline-success w-50" type="button">
                    <i class="fa fa-smile-wink"></i> Publico
                </button>
                <button *ngIf="!laboratorio.status" (click)="laboratorio.status = true" class="btn btn-outline-danger w-50" type="button">
                    <i class="fa fa-dizzy"></i> No Publico
                </button>
                
            </div>


            <div class="form-group text-center">

                <button type="submit" 
                        class="btn btn-primary w-25"> 
                    <i class="fa fa-save"></i>
                    Guardar
                </button>

            </div>

        </form>

    </div>
</div>