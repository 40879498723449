import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LaboratoriosComponent } from './paginas/laboratorios/laboratorios.component';
import { LaboratorioComponent } from './paginas/laboratorio/laboratorio.component';
import { HomeComponent } from './paginas/home/home.component';
import { CodigosComponent } from './paginas/codigos/codigos.component';
import { CodigoComponent } from './paginas/codigo/codigo.component';
import { ListadoComponent } from './paginas/codigos/listado/listado.component';
import { LoginComponent } from './paginas/login/login.component';
import { AuthGuard } from './core/authguard';


const routes: Routes = [

  { path : '' , component : LaboratoriosComponent, canActivate: [AuthGuard] },
  { path : 'laboratorios' , component : LaboratoriosComponent, canActivate: [AuthGuard] },
  { path : 'login' , component : LoginComponent },
  { path : 'laboratorios' , component : LaboratoriosComponent, canActivate: [AuthGuard] },
  { path : 'laboratorios/:id' , component : LaboratorioComponent, canActivate: [AuthGuard] },
  { path : 'laboratorios/codigos/:id' , component : CodigosComponent, canActivate: [AuthGuard] },
  { path : 'laboratorios/codigos/crear/:id' , component : CodigoComponent, canActivate: [AuthGuard] },
  { path : 'laboratorios/codigos/listado/:id/:codigo' , component : ListadoComponent, canActivate: [AuthGuard] },
  { path : '**' , pathMatch: 'full' , redirectTo: 'home' },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
