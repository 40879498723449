export class LaboratorioModel{
    
    id: string; 
    nombre:string;
    email:string;
    password:string;
    cantidad:string;
    status:boolean;

    constructor(){
        this.status = true;
    }
}