import {NgModule} from "@angular/core";
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMenuModule } from '@angular/material/menu';
@NgModule({
  imports: [
  CommonModule, 
  MatToolbarModule,
  MatCardModule,
  MatDialogModule,
  MatMenuModule,
  ],
  exports: [
  CommonModule,
   MatToolbarModule, 
   MatCardModule, 
   MatDialogModule, 
   MatMenuModule,
   ],
})
export class CustomMaterialModule { }