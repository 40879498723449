import { Component, OnInit } from '@angular/core';
import { LaboratoriosService } from 'src/app/services/laboratorios.service';
import  Swal  from 'sweetalert2';
import { Observable } from 'rxjs';
import { LoginService } from 'src/app/services/login.service';
import { User } from 'src/app/modelos/user.model';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-laboratorios',
  templateUrl: './laboratorios.component.html',
  styleUrls: ['./laboratorios.component.scss']
})
export class LaboratoriosComponent implements OnInit {

  laboratorios = [];
  user: User;


  constructor( private service : LaboratoriosService, private loginService:LoginService, private route: ActivatedRoute, private router: Router ) {
    
    this.loginService.user.subscribe( (user:User) => 
      { 
        if(user.rol != "admin"){  
         this.router.navigate(['/laboratorios/codigos/'+user.id]);
        }

      });

  }

  ngOnInit(): void {

    let peticion: Observable<any>;

    peticion = this.service.all();

    Swal.fire({
      title: '',
      icon: 'info',
      text: 'Cargando la información',
      allowOutsideClick: false,
    });

    Swal.showLoading();

    peticion.subscribe( resp => {

      if(resp.status == "ok"){

        this.laboratorios = resp.data;
        Swal.close();

      }

    } );

  }

}
