<nav class="navbar navbar-expand-lg  navbar-dark bg-primary">
  
  <a [routerLink]="['/laboratorios']" class="navbar-brand" >eCongress</a>
  <!-- Links -->
  <div class="collapse navbar-collapse text-right" style="
  justify-content: flex-end;">

    <ul *ngIf="user == null ;else usuariosBlock" class="navbar-nav">
      <li class="nav-item">
        <a [routerLink]="['/login']" class="nav-link">Login</a>
      </li>
    </ul>

    <ng-template #usuariosBlock>

      <ul class="navbar-nav">
        <li class="nav-item">
          <a (click)="logout()" href="javascript://" class="nav-link">{{ user.mail }} - Logout</a>
        </li>
      </ul>

    </ng-template>

  </div>
  

</nav>
<div class="container">
  <router-outlet></router-outlet>
</div>