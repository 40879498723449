<div class="container">


    <h2>Login</h2>
    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
        <div class="form-group">
            <label for="username">Mail</label>
            <input type="email" formControlName="mail" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.mail.errors }" />
            <div *ngIf="submitted && f.mail.errors" class="invalid-feedback">
                <div *ngIf="f.mail.errors.required">Mail es requerido</div>
            </div>
        </div>
        <div class="form-group">
            <label for="password">Password</label>
            <input type="password" formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
            <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                <div *ngIf="f.password.errors.required">Password es requerido</div>
            </div>
        </div>
        <div class="form-group">
            <button [disabled]="loading" class="btn btn-primary">
                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                Login
            </button>
        </div>
    </form>


</div>
