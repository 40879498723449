import { Component, OnInit } from '@angular/core';

import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { LoginService } from 'src/app/services/login.service';
import Swal from 'sweetalert2';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
    loading = false;
    submitted = false;
    returnUrl: string;
  constructor(
    private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private service:LoginService
  ) { }

  ngOnInit(): void {

    this.loginForm = this.formBuilder.group({
      mail: ['', Validators.required],
      password: ['', Validators.required]
  });

  // get return url from route parameters or default to '/'
  this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';

  }

  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }

  onSubmit() {

    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
        return;
    }

    let peticion: Observable<any>;
    let login = false;
    peticion = this.service.login(this.f.mail.value, this.f.password.value);
    
    peticion.subscribe( resp => {

      if(resp.status == "ok"){

        Swal.fire({
          title: '',
          icon: 'info',
          text: 'Cargando la información',
          allowOutsideClick: false,
        });
    
        Swal.showLoading();
        Swal.close();

        if(resp.data.rol != "admin"){
          this.router.navigate(['/laboratorios/codigos/'+resp.data.id]);
        }else{
          this.router.navigate([this.returnUrl]);
        }

      }else{

        Swal.fire({
          title: '',
          icon: 'error',
          text: 'Mail o password incorrecto.',
        });


      }

    } );



  }

}
