<h1>Laboratorios</h1>
<hr>
<div class="row">
    <div class="col text-right">
        <button [routerLink]="['/laboratorios/crear']" routerLinkActive="router-link-active"  class="btn btn-primary">
            <i class="fa fa-plus"></i>
            Agregar
        </button>
    </div>
</div>
<br>
<table class="table">
    <thead class="thead-dark">
      <tr>
        <th scope="col">#</th>
        <th scope="col">Nombre</th>
        <th scope="col">Email</th>
        <th scope="col">Status</th>
        <th scope="col">Cantidad</th>
        <th scope="col">Creado</th>
        <th scope="col">Acciones</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of laboratorios">
        <th scope="row">{{ item.id }}</th>
        <td>{{ item.nombre }}</td>
        <td>{{ item.email }}</td>
        <td>{{ item.status }}</td>
        <td>{{ item.cantidad }}</td>
        <td>{{ item.created }}</td>
        <td>
          <button [routerLink]="['/laboratorios/', item.id]" routerLinkActive="router-link-active"  class="btn btn-primary w-35"> 
          <i class="fa fa-edit"></i></button>
          <button [routerLink]="['/laboratorios/codigos/', item.id]" routerLinkActive="router-link-active"  class="btn btn-primary w-35 ml-1"> 
            <i class="fa fa-plus"></i></button>
        </td>
      </tr>
    </tbody>
  </table>