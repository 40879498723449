<h1>Crear grupo de códigos</h1>
<h2>{{ laboratorio.nombre }}</h2>
<p>
  <b>Cantidad Total:</b> {{ laboratorio.cantidad }}
</p>
<p>
    <b>Cantidad Restante:</b> {{ cantidadRestante }}
</p>
<hr>
<div class="row">

    <div class="col">
        <button [routerLink]="['/laboratorios/codigos/', id]" routerLinkActive="router-link-active"  class="btn btn-danger">
            <i class="fa fa-arrow-left"></i>
            Regresar
        </button>
    </div>


</div>
<br>

<div class="row">
    <div class="col">

        <form (ngSubmit)="guardar( f )" #f="ngForm">

            <div class="form-group">
                <label>ID</label>
                <input type="text"
                        class="form-control"
                        disabled="disabled"
                        [(ngModel)]="codigo.id" 
                        name="id" />
                <small class="form-text text-muted">Este campo es autogenerado.</small> 
            </div>

            <div class="form-group">
                <label>Nombre</label>
                <input type="text"
                        class="form-control"
                        [(ngModel)]="codigo.nombre" 
                        name="nombre"
                        placeholder="Nombre del grupo" required />
            </div>

            

            <div class="form-group">
                <label>Cantidad</label>
                <input type="text"
                        min="0"
                        class="form-control"
                        [(ngModel)]="codigo.cantidad" 
                        name="cantidad"
                        placeholder="Cantidad de códigos" required />
            </div>

            <div class="form-group text-center">

                <button type="submit" 
                        class="btn btn-primary w-25"> 
                    <i class="fa fa-save"></i>
                    Guardar
                </button>

            </div>

        </form>

    </div>
</div>
